import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
// import cryptoTableReducer from './cryptoTableReducer';
// import newOrderTableReducer from './newOrderTableReducer';
import customizerReducer from './customizerReducer';
import authenticationReducer from './authenticationReducer';
import modalReducer from './modalReducer';
import templateReducer from './templateReducer';
import departmentReducer from './departmentReducer';
import modalSelectedHeaderReducer from './modalSelectedHeaderReducer';
import functionReducer from './functionReducer';
import expertiseReducer from './expertiseReducer';
import descriptionReducer from './descriptionReducer';
import emailTemplateReducer from './emailTemplateReducer';
import promptReducer from './promptReducer';
import promptLogReducer from './promptLogReducer';
import aiModelReducer from './aiModelReducer';
import recruiterReducer from './recruiterReducer';
import jobseekerReducer from './jobseekerReducer';
import newsReducer from './newsReducer';
import uploadFileReducer from './uploadFileReducer';
import tagReducer from './tagReducer';
import categoryReducer from './categoryReducer';
import faqCategoryReducer from './faqCategoryReducer';
import faqReducer from './faqReducer';
import contactReducer from './contactReducer';
import hotCareersReducer from './hotCareersReducer';
import hotIndustriesReducer from './hotIndustriesReducer';
import globalSettingReducer from './globalSettingReducer';
import dashboardReducer from './dashboardReducer';
import questionReducers from './questionReducers';

export {
  authenticationReducer,
  themeReducer,
  sidebarReducer,
  customizerReducer,
  modalReducer,
  templateReducer,
  departmentReducer,
  functionReducer,
  modalSelectedHeaderReducer,
  expertiseReducer,
  emailTemplateReducer,
  promptReducer,
  promptLogReducer,
  aiModelReducer,
  descriptionReducer,
  recruiterReducer,
  jobseekerReducer,
  newsReducer,
  uploadFileReducer,
  tagReducer,
  categoryReducer,
  faqCategoryReducer,
  faqReducer,
  contactReducer,
  hotCareersReducer,
  hotIndustriesReducer,
  globalSettingReducer,
  dashboardReducer,
  questionReducers,
};
