/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_PROMPTS,
  GET_PROMPTS_SUCCESS,
  GET_PROMPTS_FAIL,
  UPDATE_PROMPT,
  UPDATE_PROMPT_SUCCESS,
  UPDATE_PROMPT_FAIL,
} from '../actions/promptAction';
import {
  updateElementFromArrayById,
  deleteElementFromArrayById,
} from '../../shared/utils/calculation';

const initialState = {
  prompts:[],
  promptEditor: null,
  error: null,
  statusCode: '',
  isFinishRequest: true,
  data: null,
  message: '',
  isSaving: false,
  isError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROMPTS: {
      return { ...state, type: action.type };
    }
    case GET_PROMPTS_SUCCESS: {
      return { ...state, ...{ type: action.type, prompts: action.data } };
    }
    case GET_PROMPTS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    case UPDATE_PROMPT: {
      return { ...state, type: action.type, isSaving: false };
    }
    case UPDATE_PROMPT_SUCCESS: {
      const { isDeleted, id } = action.data;
      return {
        ...state,
        type: action.type,
        message: 'Update Prompt successful!',
        isSaving: true,
        isError: false,
        prompts: isDeleted
          ? deleteElementFromArrayById(state.prompts, id)
          : updateElementFromArrayById(state.prompts, action.data),
      };
    }
    case UPDATE_PROMPT_FAIL: {
      return {
        ...state,
        ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' },
        message: 'Update Prompt fail!',
        isSaving: true,
        isError: true,
      };
    }
    default:
      return state;
  }
}
