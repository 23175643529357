/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import './styleField.scss';
import { getAiModels } from '../../../../shared/api/methods/prompt';

class Api extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aiModels: [],
    };
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    onChange([e.target.name], e.target.value);
  }

  componentDidMount() {
      this.fetchData();
    }
  
  fetchData = () => {
    this.setState({ loading: true });

    getAiModels()
      .then(aiModels => {
        this.setState({
          aiModels,
          loading: false,
        });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { formField, isEdit, error } = this.props;
    const inputClass = isEdit ? 'form-control' : 'form-control input-disable';
    const isDisable = isEdit ? '' : 'disabled';

    const { aiModels } = this.state;

    return (
      <>
        <FormGroup row>
          <Col sm={6}>
            <Label for="geminiModel">Gemini Model</Label>
            <select
              id="geminiModel"
              className={inputClass}
              name="geminiModel"
              value={formField.geminiModel}
              disabled={isDisable}
              onChange={this.handleChange}
            >
              {aiModels.map((item) => (
                <option key={item._id} value={item.value}>{item.name}</option>
              ))}
            </select>
            <div className={`${error.geminiModel ? 'show-err' : ''} error-message`}>{error.geminiModel}</div>
          </Col>
        </FormGroup>
      </>
    );
  }
}

Api.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Api;
