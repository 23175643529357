/* eslint-disable no-lonely-if */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */


export const getJobApplicationStatuses = () => {
  return [
    { value: "JustApplied", text: "Mới ứng tuyển" },
    { value: "UnSuitable", text: "CV không phù hợp" },
    { value: "CvUnderReview", text: "CV đang được cân nhắc" },
    { value: "InviteInterviewPendingRC", text: "Mời Phỏng vấn - Chưa hoàn thành RC" },
    { value: "AcceptInterviewPendingRC", text: "UV đồng ý phỏng vấn - Chưa hoàn thành RC" },
    { value: "DenyInterviewPendingRC", text: "UV từ chối phỏng vấn - Chưa hoàn thành RC" },
    { value: "inviteJobSeeker", text: "Mời điền RC" },
    { value: "PositionChange", text: "Chuyển vị trí" },
    { value: "ApplicationCanceled", text: "UV cancel ứng tuyển" },
    { value: "NewCandidateSubmitted", text: "Mới nộp HSUV" },
    { value: "OlNotSend", text: "HSUV không phù hợp" },
    { value: "Processing", text: "Đang cân nhắc HSUV" },
    { value: "PotentialCandidate", text: "UV tiềm năng" },
    { value: "EvaluatingPotentialCandidate", text: "Đang đánh giá UV tiềm năng" },
    { value: "notSendIL", text: "Không mời UV phỏng vấn" },
    { value: "alreadySendIL", text: "Mời phỏng vấn" },
    { value: "considerSendIL", text: "Cân nhắc mời UV phỏng vấn" },
    { value: "candidateDeclinedIL", text: "UV từ chối phỏng vấn" },
    { value: "candidateAcceptedIL", text: "UV đồng ý phỏng vấn" },
    { value: "InterviewFailed", text: "Phỏng vấn không đạt" },
    { value: "InterviewPassed", text: "Phỏng vấn đạt" },
    { value: "statusNotSendOLOnlySave", text: "Đang đánh giá kết quả PV" },
    { value: "OlSent", text: "Mời UV thử việc" },
    { value: "OlWillNotSend", text: "Không mời UV thử việc" },
    { value: "considerOlWillSend", text: "Cân nhắc mời UV thử việc" },
    { value: "OlRejected", text: "UV từ chối thử việc" },
    { value: "OlAccepted", text: "UV đồng ý thử việc" },
    { value: "OnProbation", text: "Đang thử việc" },
    { value: "SuccessfulProbation", text: "Thử việc đạt" },
    { value: "UnsuccessfulProbation", text: "Thử việc không đạt" },
    { value: "DeniedProbation", text: "UV từ chối làm việc chính thức" },
    { value: "AcceptedOP", text: "UV đồng ý làm việc chính thức" },
  ];
};
