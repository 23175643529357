import api from '../index';
import ApiConstants from '../ApiConstants';

export const getPrompts = () => api(
  `${ApiConstants.PROMPT}`,
  null,
  'get',
  null,
);

export const updatePrompt = args => api(
  `${ApiConstants.PROMPT}/${args[0]}`,
  args[1],
  'PATCH',
  null,
);