/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_AI_MODELS,
  GET_AI_MODELS_SUCCESS,
  GET_AI_MODELS_FAIL,
} from '../actions/promptAction';

const initialState = {
  aiModels:[],
  error: null,
  statusCode: '',
  isFinishRequest: true,
  data: null,
  message: '',
  isSaving: false,
  isError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AI_MODELS: {
      return { ...state, type: action.type };
    }
    case GET_AI_MODELS_SUCCESS: {
      return { ...state, ...{ type: action.type, aiModels: action.data } };
    }
    case GET_AI_MODELS_FAIL: {
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
