/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import Table from '../../../../components/Table/index';

class PromptList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleClickEditRow = (idRow) => {
    const { promptEditing, prompts } = this.props;
    const filteredData = prompts.find(item => item.id === idRow);
    promptEditing(filteredData);
  }

  render() {
    const { prompts } = this.props;
    console.log('zzzzzz- PromptList prompts', prompts);
    return (
      <Card>
        <Table
          data={prompts}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              sortable: true,
            },
            {
              Header: 'Key',
              accessor: 'key',
              sortable: true,
            },
            {
              Header: 'Actions',
              accessor: null,
              Cell: (row) => {
                const { id } = row.original;
                return (
                  <div className="table-actions">
                    <span
                      role="presentation"
                      className="action-edit"
                      onClick={() => this.handleClickEditRow(id)}
                    >
                      Edit
                    </span>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
      </Card>
    );
  }
}
PromptList.propTypes = {
  promptEditing: PropTypes.func.isRequired,
  prompts: PropTypes.instanceOf(Object).isRequired,
};

export default PromptList;
