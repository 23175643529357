import React, { PureComponent } from 'react';
import {
  Card, Row, Col, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Input, JEditor } from '../../../../components';
import validateForm from '../../../../shared/utils/validation';
import schemapromptForm from '../validation';
import './styleForm.scss';

const initFormInputs = { name: '', content: ''  };

class PromptForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      formInputs: initFormInputs,
      promptId: '',
      error: {},
    };
  }

  componentWillMount = () => {
    const { data } = this.props;
    if (data) {
      this.setState({
        promptId: data.id,
        formInputs: {
          name: data.name, 
          content: data.content,
          aiModelId: data.aiModelId,
        }
      });
    }
  }

  handleChangeInput = (e) => {
    const { formInputs } = this.state;
    const { name, value } = e.target;
    this.setState({ formInputs: { ...formInputs, [name]: value } });
  }

  onChangeContent = (value) => {
    const { formInputs } = this.state;
    this.setState({ formInputs: { ...formInputs, content: value } });
  }

  handleClickSubmit = () => {
    const { formInputs } = this.state;
    const { isError, error: err } = validateForm(schemapromptForm, formInputs);
    if (isError) {
      this.setState({ error: err });
      return;
    }

    const { onSubmit } = this.props;
    onSubmit(formInputs);
  }

  handleCancel = () => {
    const { handleCancelForm } = this.props;
    handleCancelForm();
  }

  render() {
    const { aiModels, isEdit } = this.props;
    const inputClass = isEdit ? 'form-control' : 'form-control input-disable';
    const {
      formInputs, promptId, error,
    } = this.state;

    return (
      <Card className="form-wrapper">
        <Row>
          <Col md={12}>
            <h4>{`${promptId ? 'Update' : 'Create'}`} Prompt</h4>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={6}>
            <Label for="enSubjectInput">Name</Label>
            <Input
              type="text"
              className=""
              name="name"
              value={formInputs.name}
              onChange={this.handleChangeInput}
              errorMesssage={error.name || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label for="content">
              AI Model{' '}
            </Label>
            <select
              className={inputClass}
              name="aiModelId"
              value={formInputs.aiModelId}
              onChange={this.handleChangeInput}
              errorMesssage={error.aiModelId || ''}
            >
              {aiModels.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </Col>
          <Col md={12}>
            <Label for="content">
              Content{' '}
              <span style={{fontWeight: 'normal'}}>
                <i>Placeholder có sẵn: </i>
                {['{checklistName}', '{nachecklistDescriptionme}', '{recruiterQuestions}', '{candidateAnswers}'].join(', ')}
              </span>
            </Label>
            <JEditor
              name="content"
              content={formInputs.content}
              onChangeEditor={value => this.onChangeContent(value)}
            />
          </Col>
        </Row>
        <div className="card-form-wrapper">
          <button
            type="button"
            color="primary"
            className="card-form__btn card-form__btn--submit"
            onClick={() => this.handleClickSubmit()}
          >
            {`${promptId ? 'Save' : 'Add'}`}
          </button>
          <button
            type="button"
            color="danger"
            className="card-form__btn card-form__btn--cancel"
            onClick={() => this.handleCancel()}
          >
            Cancel
          </button>
        </div>
      </Card>
    );
  }
}
PromptForm.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  aiModels: PropTypes.instanceOf(Array).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCancelForm: PropTypes.func.isRequired,
};

export default PromptForm;
