import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PromptList from './components/promptLogList';
import { BasicNotification } from '../../../components/Notification/index';
import {
  getPromptLogs as getPromptLogsAction,
} from '../../../redux/actions/promptLogAction';

let notificationRU = null;

class PromptLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isOpenForm: false,
      promptEdit: null,
      isShowNotify: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillMount = () => {
    const { cookies, getPromptLogs } = this.props;
    console.log('this.props', this.props);
    const token = cookies.get('token') || '';
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  promptEditById = (item) => {
    this.setState({ isOpenForm: true, promptEdit: item });
  }


  handleExitForm = () => {
    this.setState({ isOpenForm: false, promptEdit: null });
  }

  onSubmit = ({ name, content }) => {
    const { promptEdit } = this.state;
    const { updatePrompt } = this.props;
    this.setState({ isOpenForm: false, promptEdit: null, isShowNotify: true });
    console.log("avcds");
    if (promptEdit) {
      return updatePrompt(promptEdit.id, {
        name,
        content
      });
    }
  }

  // eslint-disable-next-line arrow-body-style
  show = (color, message) => {
    return notificationRU.notice({
      content: <BasicNotification
        title="Prompt"
        color={color}
        message={message}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  }

  render() {
    const { cookies, promptLog } = this.props;
    console.log('zzzz - this.props', this.props);
    const {
      hasToken,
      isOpenForm,
      isShowNotify,
    } = this.state;
    if (isShowNotify && prompt.message) {
      if (prompt.isError) {
        this.show('warning', prompt.message);
      } else {
        this.show('success', prompt.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || prompt.statusCode === '401') {
      if (prompt.statusCode === '401') cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Prompt Log</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {isOpenForm ? (
            <>Modal</>
          ) : (
            <PromptList />
          )}
        </Card>
      </Container>
    );
  }
}

PromptLog.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getPromptLogs: PropTypes.func.isRequired,
  promptLogs: PropTypes.instanceOf(Object).isRequired,

};

const mapStateToProps = (state) => {
  const { promptLog } = state;
  return {
    promptLog,
  };
};
const mapDispatchToProps = dispatch => ({
  getPromptLogs: () => dispatch(getPromptLogsAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(PromptLog), withTranslation('common')(PromptLog));
