/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Card, Modal } from 'reactstrap';
import { getPromptLogs } from '../../../../shared/api/methods/promptLog';
import ReactTable from 'react-table';
import moment from 'moment';
import renderHTML from 'react-render-html';

class PrompLogtList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      pageSize: 10,
      totalPages: 0,
      loading: false,

      isViewDetails: false,
      selectedPromptLog: null,
    };
  }

  handleClickViewRow = (idRow) => {
    const { data } = this.state;
    // fetch details data
    this.setState({
      isViewDetails: true,
      selectedPromptLog: data.find(item => item.id === idRow),
    });
  }

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.fetchData(page, pageSize);
  }

  fetchData = (page, pageSize) => {
    this.setState({ loading: true });

    const skip = page * pageSize;
    const limit = pageSize;

    getPromptLogs(skip, limit)
      .then(result => {
        this.setState({
          data: result.data,
          totalPages: Math.ceil(result.totalCount[0].count / pageSize),
          loading: false,
        });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        this.setState({ loading: false });
      });
  };

  handleCancelModal = () => {
    this.setState({
      isViewDetails: false,
      selectedPromptLog: null,
    });
  }

  generateDetaislModal() {
    const { isViewDetails, selectedPromptLog } = this.state;
    if (!selectedPromptLog) return ;
    return (
      <Modal isOpen={isViewDetails} size='lg'>
        <div className="modal-header">
          <h5 className="modal-title">Prompt Log Details</h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleCancelModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table>
            <tr><td><strong>Matching Percent</strong></td><td></td><td>{selectedPromptLog.evaluationPercent ? selectedPromptLog.evaluationPercent + ' %': '' }</td></tr>
            <tr><td><strong>PromptTokenCount</strong></td><td></td><td>{selectedPromptLog.promptTokenCount}</td></tr>
            <tr><td><strong>PandidatesTokenCount</strong></td><td></td><td>{selectedPromptLog.candidatesTokenCount}</td></tr>
            <tr><td><strong>PromptCost</strong></td><td></td><td>{selectedPromptLog.promptCost}</td></tr>
            <tr><td><strong>CandidatesCost</strong></td><td></td><td>{selectedPromptLog.candidatesCost}</td></tr>
            <tr><td><strong>PromptCostVND</strong></td><td></td><td>{selectedPromptLog.promptCostVND}</td></tr>
            <tr><td><strong>CandidatesCostVND</strong></td><td></td><td>{selectedPromptLog.candidatesCostVND}</td></tr>
            <tr><td><strong>Model</strong></td><td></td><td>{selectedPromptLog.model}</td></tr>
            <tr><td><strong>Runtime</strong></td><td></td><td>{selectedPromptLog.runtime}</td></tr>
            <tr>
              <td style={{verticalAlign: 'top'}}>
                <strong>Question</strong></td>
              <td></td><td>{renderHTML ((selectedPromptLog.question || 'Không có dữ liệu').replace(
              /\*\*(.*?)\*\*/g,
              '<strong>$1</strong>',
            ))}</td></tr>
            <tr>
              <td style={{verticalAlign: 'top'}}><strong>Answer</strong></td>
              <td></td><td>{renderHTML((selectedPromptLog.answer || 'Không có dữ liệu').replace(
              /\*\*(.*?)\*\*/g,
              '<strong>$1</strong>',
            ))}</td></tr>
          </table>
        </div>
        <div className="modal-footer">
          <div className="Modal__footer">
            <button
              type="button"
              color="danger"
              className="card-form__btn card-form__btn--cancel"
              onClick={this.handleCancelModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
  )
  }

  handleFetchData = state => {
    const { page, pageSize } = state;

    // Fetch data based on updated page and pageSize
    this.setState({ page, pageSize }, () => {
      this.fetchData(page, pageSize);
    });
  };

  render() {
    const { data, totalPages, pageSize } = this.state;
    const columns = [
      {
        Header: 'Job',
        accessor: 'jobName',
      },
      {
        Header: 'Canidate Name',
        accessor: 'candidateName',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'PromptCost',
        accessor: 'promptCostVND',
      },
      {
        Header: 'CandidatesCost',
        accessor: 'candidatesCostVND',
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Runtime',
        accessor: 'runtime',
      },
      {
        Header: 'Date',
        accessor: 'updatedDate',
        Cell: (row) => {
          const { updatedDate } = row.original;
          return moment(updatedDate).format('DD-MM-YYYY HH:mm');
        },
      },
      {
        Header: 'Actions',
        accessor: null,
        Cell: (row) => {
          const { id } = row.original;
          return (
            <div className="table-actions">
              <span
                role="presentation"
                className="action-edit"
                onClick={() => this.handleClickViewRow(id)}
              >
                Details
              </span>
            </div>
          );
        },
      },
    ];
  
    return (
      <Card>
        <ReactTable
          data={data}
          pages={totalPages}
          manual // Enable manual server-side pagination
          onFetchData={this.handleFetchData} // Centralized fetching logic
          columns={columns}
          defaultPageSize={pageSize}
          className="custom-table"
          showPaginationBottom
          sortable={false}
        />
        {this.generateDetaislModal()}
      </Card>
    );
  }
}
PrompLogtList.propTypes = {};

export default PrompLogtList;
