/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import {
  GET_PROMPT_LOGS,
  GET_PROMPT_LOGS_SUCCESS,
  GET_PROMPT_LOGS_FAIL,
} from '../actions/promptLogAction';

const initialState = {
  promptLogs:[],
  error: null,
  statusCode: '',
  isFinishRequest: true,
  data: null,
  message: '',
  isSaving: false,
  isError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROMPT_LOGS: {
      return { ...state, type: action.type };
    }
    case GET_PROMPT_LOGS_SUCCESS: {
      return { ...state, ...{ type: action.type, promptLogs: action.data } };
    }
    case GET_PROMPT_LOGS_FAIL: {
      alert(555);
      return { ...state, ...{ type: action.type, statusCode: action.error ? action.error.statusCode.toString() : '' } };
    }
    default:
      return state;
  }
}
