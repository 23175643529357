import { take, call, put } from 'redux-saga/effects';
import { GET_PROMPTS, GET_PROMPTS_FAIL, GET_PROMPTS_SUCCESS, UPDATE_PROMPT, UPDATE_PROMPT_FAIL, UPDATE_PROMPT_SUCCESS } from '../actions/promptAction';
import { getPrompts, updatePrompt } from '../../shared/api/methods/prompt';

export function* getPromptsSaga() {
  while (true) {
    try {
      yield take(GET_PROMPTS);
      const data = yield call(getPrompts);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_PROMPTS_FAIL, error });
      } else {
        yield put({ type: GET_PROMPTS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_PROMPTS_FAIL, data: [] });
    }
  }
}

export function* updatePromptSaga() {
  while (true) {
    try {
      const { id, params } = yield take(UPDATE_PROMPT);
      const data = yield call(updatePrompt, [id, params]);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: UPDATE_PROMPT_FAIL, error });
      } else {
        yield put({ type: UPDATE_PROMPT_SUCCESS, data });
      }
    } catch (error) {
      const returnValue = { error };
      yield put({ type: UPDATE_PROMPT_FAIL, returnValue });
    }
  }
}