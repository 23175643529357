export const GET_PROMPT_LOGS = 'GET_PROMPT_LOGS';
export const GET_PROMPT_LOGS_SUCCESS = 'GET_PROMPT_LOGS_SUCCESS';
export const GET_PROMPT_LOGS_FAIL = 'GET_PROMPT_LOGS_FAIL';

export function getPromptLogs() {
  return {
    type: GET_PROMPT_LOGS,
  };
}

export function getPromptLogsSuccess(data) {
  return {
    type: GET_PROMPT_LOGS_SUCCESS,
    data,
  };
}

export function getPromptLogsFail() {
  return {
    type: GET_PROMPT_LOGS_FAIL,
  };
}