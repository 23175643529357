import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import PromptList from './components/promptList';
import PromptForm from './components/promptForm';
import { BasicNotification } from '../../../components/Notification/index';
import {
  getPrompts as getPromptsAction,
  updatePrompt as updatePromptAction,
} from '../../../redux/actions/promptAction';
import { name } from 'file-loader';

let notificationRU = null;

class Prompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: false,
      isOpenForm: false,
      promptEdit: null,
      isShowNotify: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({}, n => notificationRU = n);
  }

  componentWillMount = () => {
    const { cookies, getPrompts } = this.props;
    console.log('this.props', this.props);
    const token = cookies.get('token') || '';
    getPrompts();
    if (token !== '') {
      this.setState({
        hasToken: true,
      });
    }
  }

  componentWillUnmount() {
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  promptEditById = (item) => {
    this.setState({ isOpenForm: true, promptEdit: item });
  }


  handleExitForm = () => {
    this.setState({ isOpenForm: false, promptEdit: null });
  }

  onSubmit = ({ name, content }) => {
    const { promptEdit } = this.state;
    const { updatePrompt } = this.props;
    this.setState({ isOpenForm: false, promptEdit: null, isShowNotify: true });
    console.log("avcds");
    if (promptEdit) {
      return updatePrompt(promptEdit.id, {
        name,
        content
      });
    }
  }

  // eslint-disable-next-line arrow-body-style
  show = (color, message) => {
    return notificationRU.notice({
      content: <BasicNotification
        title="Prompt"
        color={color}
        message={message}
      />,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up',
    });
  }

  render() {
    const { prompt, cookies } = this.props;
    const {
      hasToken,
      isOpenForm,
      promptEdit,
      isShowNotify,
    } = this.state;
    if (isShowNotify && prompt.message) {
      if (prompt.isError) {
        this.show('warning', prompt.message);
      } else {
        this.show('success', prompt.message);
      }
      this.setState({ isShowNotify: false });
    }
    if (!hasToken || prompt.statusCode === '401') {
      if (prompt.statusCode === '401') cookies.set('token', '', { path: '/' });
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Prompt</h3>
          </Col>
        </Row>
        <Card body className="mb-5">
          {isOpenForm ? (
            <PromptForm
              data={promptEdit}
              handleCancelForm={() => this.handleExitForm()}
              onSubmit={(formInput) => this.onSubmit(formInput)}
            />
          ) : (
            <PromptList
              prompts={prompt.prompts}
              promptEditing={item => this.promptEditById(item)}
            />
          )}
        </Card>
      </Container>
    );
  }
}

Prompt.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getPrompts: PropTypes.func.isRequired,
  updatePrompt: PropTypes.func.isRequired,
  prompt: PropTypes.instanceOf(Object).isRequired,

};

const mapStateToProps = (state) => {
  const { prompt } = state;
  return {
    prompt,
  };
};
const mapDispatchToProps = dispatch => ({
  getPrompts: () => dispatch(getPromptsAction()),
  updatePrompt: (...args) => dispatch(updatePromptAction(...args)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(Prompt), withTranslation('common')(Prompt));
