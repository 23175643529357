import { take, call, put } from 'redux-saga/effects';
import { GET_PROMPT_LOGS, GET_PROMPT_LOGS_FAIL, GET_PROMPT_LOGS_SUCCESS } from '../actions/promptLogAction';
import { getPromptLogs } from '../../shared/api/methods/promptLog';

export function* getPromptLogsSaga() {
  while (true) {
    try {
      yield take(GET_PROMPT_LOGS);
      const data = yield call(getPromptLogs);
      if (data === null || Object.prototype.hasOwnProperty.call(data, 'error')) {
        const { error } = data;
        yield put({ type: GET_PROMPT_LOGS_FAIL, error });
      } else {
        yield put({ type: GET_PROMPT_LOGS_SUCCESS, data });
      }
    } catch (error) {
      yield put({ type: GET_PROMPT_LOGS_FAIL, data: [] });
    }
  }
}